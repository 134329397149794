import { useCallback } from "react"

const useMouseParallax = () => {
  // Parallax Effect
  const handleMouseParallax = useCallback(params => {
    const parallaxBox = document.querySelector(".main")

    const mouseParallax = (id, left, top, mouseX, mouseY, speed) => {
      var obj = document.getElementById(id)
      var parentObj = document.querySelector(".main"),
        containerWidth = parseInt(parentObj.offsetWidth),
        containerHeight = parseInt(parentObj.offsetHeight)
      obj.style.left =
        left -
        ((mouseX - (parseInt(obj.offsetWidth) / 2 + left)) / containerWidth) *
          speed +
        "px"
      obj.style.top =
        top -
        ((mouseY - (parseInt(obj.offsetHeight) / 2 + top)) / containerHeight) *
          speed +
        "px"
    }

    parallaxBox.onmousemove = event => {
      event = event || window.event
      let x = event.clientX - parallaxBox.offsetLeft,
        y = event.clientY - parallaxBox.offsetTop

      params.forEach(param => {
        const { id, speed, left, top } = param

        mouseParallax(id, left, top, x, y, speed)
      })
    }
  }, [])

  return [handleMouseParallax]
}

export default useMouseParallax
