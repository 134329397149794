import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import useBreakpoint from "../hooks/useBreakpoints"
import ParallaxHome from "../components/ParallaxHome"

/* Styles */
import "../styles/pages/home.scss"

const IndexPage = () => {
  const { bgHome, bgHomeMovil, frame06 } = useStaticQuery(
    graphql`
      query {
        bgHome: file(relativePath: { eq: "home/desktop-bg-home.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bgHomeMovil: file(relativePath: { eq: "home/bg-mobile-home.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        frame06: file(relativePath: { eq: "home/frame_06.png" }) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageBgHome = bgHome.childImageSharp.fluid
  const imgBgHomeMovil = bgHomeMovil.childImageSharp.fluid
  const imgFrame06 = frame06.childImageSharp.fluid

  // Breakpoints
  const breakpoints = useBreakpoint()
  const { md } = breakpoints

  // Media querys breakpints Bakground Images
  // const sources = breakpoints.md ? imageBgHome : imgBgHomeMovil

  const sources = [
    imgBgHomeMovil,
    {
      ...imageBgHome,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout pageInfo={{ pageName: "index" }} bgImage={sources}>
      <SEO
        title="Home"
        keywords={[
          `Agencia`,
          `Transformacion Digital`,
          `The Hybrid`,
          `Growth`,
          `Rosario`,
          `Innovación`,
        ]}
      />
      <Container className="text-center">
        <Row>
          {md ? (
            <ParallaxHome />
          ) : (
            <Col>
              <div className="movil-layer-wrap">
                <div className="movil-layer" id="l7">
                  <Image
                    className="img-fluid"
                    fluid={imgFrame06}
                    alt="bg-shape"
                  />
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
