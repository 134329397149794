import React, { useEffect } from "react"
import { Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import useMouseParallax from "../../hooks/useMouseParallax"

/* Styles */
import "./styles.scss"

const ParallaxHome = () => {
  const { frame02, frame03, frame04, frame06 } = useStaticQuery(
    graphql`
      query {
        frame02: file(relativePath: { eq: "home/frame_02.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        frame03: file(relativePath: { eq: "home/frame_03.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        frame04: file(relativePath: { eq: "home/frame_04.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        frame06: file(relativePath: { eq: "home/frame_06.png" }) {
          childImageSharp {
            fluid(quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imgFrame02 = frame02.childImageSharp.fluid
  const imgFrame03 = frame03.childImageSharp.fluid
  const imgFrame04 = frame04.childImageSharp.fluid
  const imgFrame06 = frame06.childImageSharp.fluid

  // Parallax Effect
  const [handleMouseParallax] = useMouseParallax()

  useEffect(() => {
    handleMouseParallax([
      {
        id: "l2",
        speed: 15,
        top: document.getElementById("l2").offsetTop,
        left: document.getElementById("l2").offsetLeft,
      },
      {
        id: "l3",
        speed: 30,
        top: document.getElementById("l3").offsetTop,
        left: document.getElementById("l3").offsetLeft,
      },
      {
        id: "l4",
        speed: 65,
        top: document.getElementById("l4").offsetTop,
        left: document.getElementById("l4").offsetLeft,
      },
      {
        id: "l6",
        speed: 25,
        top: document.getElementById("l6").offsetTop,
        left: document.getElementById("l6").offsetLeft,
      },
    ])
  }, [handleMouseParallax])

  return (
    <Col className="position-relative">
      <div className="layer" id="l4">
        <Image className="img-fluid" fluid={imgFrame04} alt="bg-shape" />
      </div>
      <div className="layer" id="l2">
        <Image className="img-fluid" fluid={imgFrame02} alt="bg-shape" />
      </div>
      <div className="layer" id="l3">
        <Image className="img-fluid" fluid={imgFrame03} alt="bg-shape" />
      </div>
      <div className="layer" id="l6">
        <Image className="img-fluid" fluid={imgFrame06} alt="bg-shape" />
      </div>
    </Col>
  )
}

export default ParallaxHome
